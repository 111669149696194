import React from "react"
import { Helmet } from "react-helmet";
import { Footer } from "../components/Footer/Footer";
import NavbarTwo from '../components/Navbar/NavbarTwo';
import { useTheme } from '../contexts/ThemeContext';
import './blog-post.scss'; 
export default function Template({
  data 
}) {
  const { markdownRemark: post } = data 
  const { isDarkTheme } = useTheme();
  return (
      <>
      <NavbarTwo />
      <div className={isDarkTheme ? "blog-post-container blog-post-container-dark" :  "blog-post-container"}>
        <div className="blog-post-content-container">
        <Helmet title={`Your Blog Name - ${post.frontmatter.title}`} />
        <div className="blog-post">
          <h1>{post.frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`